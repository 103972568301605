<template>
  <v-container>
    <div id="weather__info">
      <div class="weather__content">
        <div>
          <div class="weather__title">오늘</div>
        </div>

        <div class="line"></div>

        <div class="weather__status">
          <img :src="require('@/assets/monitoring/' + weather_info.current.weather_icon + '.svg')" :title=weather_info.current.weather_content />

          <span class="weather_degree mr-1"> {{this.weather_info.current.temp}}℃ </span>
          <div class="weather__temp">
            <span style="color:#88191a; font-size:10px;">{{ this.weather_info.current.highest_temp}}℃ </span>
            <span> / </span>
            <span style="color:#0044B1; font-size:10px;"> {{this.weather_info.current.lowest_temp}}℃ </span>
          </div>
        </div>
      </div>

        <div class="weather__content tomorrow_content ml-1">
        <div>
          <div class="weather__title">내일</div>
        </div>

        <div class="line"></div>

        <div class="weather__status tomorrow">
          <img :src="require('@/assets/monitoring/' + weather_info.tomorrow.weather_icon + '.svg')" :title=weather_info.tomorrow.weather_content />

          <div class="weather__temp">
            <span style="color:#88191a;"> {{ this.weather_info.tomorrow.highest_temp }}℃ </span>
            <span> / </span>
            <span style="color:#0044B1;"> {{this.weather_info.tomorrow.lowest_temp}}℃ </span>
          </div>
        </div>
      </div>

      <div class="watt__content ml-1" @click="$emit('gowatt')"  v-if="weather_info.watt">
        <div class="weather__title none"> 실시간 15분 전력</div>
        <div class="line none" style="border-left: 2px solid white;"> </div>

          <v-icon
            class="mr-1"       
            small
            color=white>
            mdi-lightbulb-on-outline
          </v-icon>
          <span style="font-weight:500;"> {{ weather_info.watt }}  
            <span class="size">kw</span>
          </span>
        </div>
      </div>

  </v-container>
</template>

<script>
export default {
  name: "WeatherInfo",
  props: {
    weather_info: [],
  },
  data() {
    return {
      // icon_sunny: require("@/assets/monitoring/sunny.svg"),
      // icon_sunny_night: require("@/assets/monitoring/sunny_night.svg"),
      // icon_cloudy: require("@/assets/monitoring/cloudy.svg"),
      // icon_partly_cloudy: require("@/assets/monitoring/partly_cloudy.svg"),
      // icon_partly_cloudy_night: require("@/assets/monitoring/partly_cloudy_night.svg"),
      // icon_rain: require("@/assets/monitoring/rain.svg"),
      // icon_thunder: require("@/assets/monitoring/thunder.svg"),
      // icon_snow: require("@/assets/monitoring/snow.svg"),
      // icon_windy: require("@/assets/monitoring/windy.svg"),
      // icon_drizzle: require("@/assets/monitoring/drizzle.svg"),
      // icon_strong_wind: require("@/assets/monitoring/strong_wind.svg"),
      // icon_refresh: require("@/assets/monitoring/icon_refresh.svg"),
    };
  },
  created()  {
    // console.log(this.weather_info);
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },

  },
};
</script>
<style lang="scss" scoped>
#weather__info{
  display:flex;
  flex-wrap: wrap;
  letter-spacing: -1px;
  justify-content: center;
}
.weather__content{
  display:flex;
  border:1px solid #C6C9CE;
  width:200px;
  border-radius: 20px;
  padding:5px;
  align-items: center;
  margin:3px;
}
.tomorrow_content{
  width:170px;
}
.watt__content{
  display:flex;
  align-items: center;
  background: #9EAFA2;
  background-blend-mode: lighten;
  color:white;
  border-radius: 20px;
  padding:5px;
  margin:3px;
  align-content: center;
  cursor:pointer;
}
.weather__title{
  text-align:center;
  font-size:13px;
  font-weight:bold;
}
.line {
  margin: 0px 5px 0px 10px;
  border-left : 2px solid #6B7B6E;
  height : 20px;
}
.underline {
  border: 1px solid white;
  width:90px;
}

img{
  margin-top:-5px;
  width:40px;
  height:35px;
}
.weather_degree{
  color:#6B7B6E;
  font-size:15px; 
  width:40px;
  letter-spacing: -2px; 
  font-weight:600; 
}
.weather__status{
  display:flex;
  text-align:center;
  align-items: center;
}
.tomorrow img{
  width:50px;
}
.weather__temp{
  // margin-top:-15px;
  font-size:11px;
}

@media (min-width: 360px) and (max-width: 767px) {
  .weather__temp{
    display:none;
  }
  .weather__content{
    width:110px;
  }
  .weather__status{
    width:50px;
  }
  .weather__status img{
    width:25px;
  }
  .weather_degree{
    font-size:10px;
    letter-spacing: -2px;
  }
  .none{
    display:none;
  }
  .size{
    font-size:10px;
    padding-right:5px;
  }
  .watt__content{
    width:25vw;
  }
}

</style>
