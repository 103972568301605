<template>
  <v-container v-show="isreload" fluid>
    <v-card rounded-pill outlined max-width="800px" class="mx-auto pa-1" style="border-radius:20px;" color="transparent" >
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 200px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다.."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;" 
          id="farm" 
          ref="farm" 
          label="사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;"
          id="building" 
          ref="building" 
          label="건물" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          placeholder="미선택 시 사업장전체를 모니터합니다"
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px;"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refresh()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <v-card max-width="1500px" class="mx-auto" height="auto" outlined color="transparent" >
      <v-layout wrap>
      <div class="header_layout">
        <div>
          <v-select  rounded 
          class="selectedHeaders"
          v-model="selectedHeaders" 
          :items="headers" 
          label="조회 지표" 
          density="comfortable"
          item-disabled="disabled"
          color="#3a4f3f"
          :menu-props="{ top: false, offsetY: true }"
          multiple 
          outlined 
          return-object
          dense
          >

          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action >
              <v-checkbox :input-value="active" color="#80a087"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >
                  <div style="color:#3a4f3f;"> {{ item.text }}</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 4" class="selectChip">
              <span >{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 3" class="grey--text caption"> 등 </span>
          </template>


          </v-select>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div style="margin-top:-10px;">
        <WeatherInfo
          :weather_info="weather_info"
          @gowatt="gowatt(null)"
        />
      </div>
      </v-layout>

      <v-data-table dense height="650px" class="elevation-2 v-data-table" style=" max-width:100%;  table-layout:fixed;"
        id="items" 
        ref="items"
        :loading="loading" 
        :headers="showHeaders"
        :items="items"
        :items-per-page= "500"
        no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
        fixed-header
        hide-default-footer
        sort-by="room_name"
        group-by="building_name"
        show-group-by
        item-key="room_cd"
        mobile-breakpoint="0"
        single-select
        >


        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="14">
            <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon> 
            <span>{{ items[0].building_name }} </span>
            <span  @click="gowatt({code:items[0].building_cd, name:items[0].building_name})" class="watt_info" v-if="items[0].building_watt !== '-'">
              <img :src="icon_watt" class="icon_bolt"/>
              <span class="watt_number">{{ items[0].building_watt}}</span>
              <span style="font-weight:600;">kw</span>
            </span>
          </th>
        </template>
        
        <template v-slot:item= "{ item }">
          <tr>
            <td v-if="showColumn('room_name')">{{ item.room_name }}</td>
            <td v-if="showColumn('temp')" >{{ item.temp }}<span :class="(item.temp == '-') ? 'no_unit' : 'unit' "> ℃</span></td>
            <td v-if="showColumn('highestTemp')" class="text_red">{{ item.highestTemp }}</td>
            <td v-if="showColumn('lowestTemp')" class="text_blue">{{ item.lowestTemp }}</td>
            <td v-if="showColumn('feel')" class="text_gray">{{ item.feel }}</td>
            <td v-if="showColumn('hum')">{{ item.hum }}<span :class="(item.hum == '-') ? 'no_unit' : 'unit' "> %</span></td>
            <td v-if="showColumn('co2')">{{ item.co2 }}<span :class="(item.co2 == '-') ? 'no_unit' : 'unit_ppm' "> ppm</span></td>
            <td v-if="showColumn('nh3')">{{ item.nh3 }}<span :class="(item.nh3 == '-') ? 'no_unit' : 'unit_ppm' "> ppm</span></td>
            <td v-if="showColumn('h2s')">{{ item.h2s }}<span :class="(item.h2s == '-') ? 'no_unit' : 'unit_ppm' "> ppm</span></td>
            <td v-if="showColumn('checkTime')"> <span :class="( item.validTime || item.checkTime == '-') ? '' : 'cell_red text_white'"> {{ item.checkTime }} </span></td>
            <td v-if="showColumn('pig_cnt')">{{ item.pig_cnt }}</td>
            <td v-if="showColumn('nowVent')" class="fan_info">{{ item.nowVent }}</td>
            <td v-if="showColumn('now_temp')">{{ item.now_temp }}</td>

            <td v-if="showColumn('action')"  nowrap>
              <button rounded class="elevation-3 link_btn"
                @click="gotoBarometer(item)"
                >
                지표조회
              </button>
              <button rounded class="elevation-3 link_btn"
                v-if="item.services.dailyrecord"
                @click="gotoEvent(item)">
                관리일지
              </button>
              <button rounded class="elevation-3 link_btn" :class="{ link_btn_red : item.now_mode === '' , link_btn_white : item.now_mode === 0}"
                v-if="item.services.fancontrol && item.controllers[0].mac"
                @click="gotoSettingControl(item)">
                환기제어
              </button>
              <button rounded class="elevation-2 link_btn" :class="{ link_btn_red : item.now_mode === '' , link_btn_white : item.now_mode === 0}"
                v-else-if="item.controllers[0].mac"
                @click="gotoSettingControl(item)">
                출력현황
              </button>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-card max-width="1500px" class="mx-auto" height="auto" outlined color="transparent" >
      <v-row>
        <v-col md="2">
        <v-select dense  class="interval" 
            id="setInterval" 
            ref="setInterval"
            label="" 
            :items="setIntervalItems"
            item-text="descripton"
            return-object
            v-model="setInterval"
            @change="changed = true; refreshList()"
          />  
        </v-col>
        <v-col class="mt-1 text-left">
          마다 새로고침
        </v-col>
    </v-row>


    </v-card>
  
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import WeatherInfo from "./child/WeatherInfo.vue";
// import Common from "@/utils/custom/common.js";

var setTime = 0;
var header_list = [];
var changed = false;


export default {
  name: 'Summary',
  components: {
    WeatherInfo,
  },
  data: () => ({
    mobileBreakpoint: 600,

    icon_watt: require("@/assets/monitoring/lightning-bolt.svg"),

    items: [],
    headers: [],

    headersMap: {
      room_name : {text: '구역', value: 'room_name', groupable: false, selected: true , disabled: true},
      temp : {text: '온도', value: 'temp', groupable: false, selected: true, disabled: true },
      highestTemp : {text: '최고', value: 'highestTemp', width: '50px', groupable: false, selected: true },
      lowestTemp : {text: '최저', value: 'lowestTemp', width: '50px', font_size: '5px',groupable: false, selected: true },
      feel : {text: '체감', value: 'feel', width: '70px', groupable: false, selected: true },
      hum : {text: '습도', value: 'hum', groupable: false, selected: true },
      co2 : {text: '이산화탄소', value: 'co2', groupable: false, selected: true },
      nh3 : {text: '암모니아', value: 'nh3', groupable: false, selected: true },
      h2s : {text: '황화수소', value: 'h2s', groupable: false, selected: true },
      checkTime : {text: '측정시간', value: 'checkTime', groupable: false, selected: true, disabled: true },
      pig_cnt : {text: '두수', value: 'pig_cnt' , groupable: false, selected: true},
      nowVent : {text: '팬출력', value: 'nowVent', groupable: false, selected: true },
      now_temp : {text: '팬온도', value: 'now_temp', groupable: false, selected: true },
      action : {text: '바로가기', value: 'action', groupable: false, selected: true, disabled: true, sortable:false },
    },
    selectedHeaders: [],

    setInterval: {min: 30, descripton: "30 분"},
    setIntervalItems: [
      { min: 10, descripton: "10 분"},
      { min: 20, descripton: "20 분"},
      { min: 30, descripton: "30 분"},
      { min: 60, descripton: "1 시간"},
    ],
    
    isreload: false,
    loading: false,
    msgFlag: true,
    
    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    weather_info: {current: {weather_icon: "unknown"},tomorrow: {weather_icon: "unknown"}},

  }),
  async created() {
    this.headers = Object.values(this.headersMap);

    // URL 파라미터 유무 검사
    let url = new URL(window.location.href);
    // URLSearchParams 객체
    let urlParams = url.searchParams;
    // URLSearchParams.get()
    let cc = urlParams.get('cc');
    let cn = urlParams.get('cn');
    let fc = urlParams.get('fc');
    let fn = urlParams.get('fn');
    
    if (fc) {     // URL파라미터 벋으면 페이지 이동시 URL파라미터 사라지지 않음, 방지를 위한 리로드 : 연구 개선 필요
      // console.log("farm",cc,cn,fc,fn);
      sessionStorage.setItem("paramInfo",JSON.stringify(
        { company:{code: cc, name: cn},
          farm:{company_cd: cc, company_name: cn, code: fc, name: fn},
          building:{},
        })
      );
      history.replaceState({}, null,location.pathname);
      location.reload();
    } else {
      
      Apis.getIpClient((res) => {
        // console.log(res);  
        let client = res
        Apis.pageCount({ //페이지뷰 카운터
          page_name: `${client.city}/${client.country}/${client.region}/${this.$route.name}`,
          url: this.$route.path,
          ip: client.ip,
          division: "pigro",
        },() => {  // 정상처리
          // console.log("pageCount 호출",res);
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("pageCount 호출 오류",err);
        });
      }).catch( (err) => {  // API 오류 처리
          console.log("getIPI 호출 오류",err)
      }) 

      this.isreload = true;
      await this.comboCompanies();
      await this.comboFarms();
      await this.comboBuildings();

      let cookie_interval = VueCookies.get("summary_interval");
      if (cookie_interval !== "undefined" && cookie_interval) {
        this.setInterval = cookie_interval;
      } 

      let cookie_info = VueCookies.get("summary_info");

      let paramInfo = JSON.parse(sessionStorage.getItem("paramInfo"));
      if (paramInfo) {
        cookie_info = paramInfo;
        sessionStorage.removeItem("paramInfo");
      }

      if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
        this.building = cookie_info.building || {};
      } else {
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      this.refreshList();
    }
  },
  destroyed() {
    clearTimeout(setTime);
    // console.log("clearTimeout",setTime);
  },
  computed:{
    showHeaders () {
      let name_list = [];
      this.selectedHeaders.forEach( item => name_list.push(item.value));   // 선택 항목 텍스트 리스트
      if (!changed) {  
        let cookie = this.headers.filter(s => name_list.includes(s.value));  // 선택항목 오브젝트 리스트
        // VueCookies.set(this.farm.code + "_selected_monitoring_summary",{ list: cookie, name_list: name_list},"30d");  // 농장에대한 선택 쿠키 저장
        localStorage.setItem(this.farm.code + "_selected_monitoring_summary",JSON.stringify({ list: cookie, name_list: name_list}));  // 농장에대한 선택 쿠키 저장
      }
      header_list = name_list
      changed = false;  

      return this.headers.filter(s => header_list.includes(s.value));   // 선택항목 오브젝트 리스트 리턴
    },

  },
  methods: {
    showColumn(col){
      return this.selectedHeaders.find(s => s.value === col);
    },
    refresh() {
      clearTimeout(setTime);
      this.refreshList();
    },
    refreshList() {
      // let cookie = VueCookies.get( this.farm.code + "_selected_monitoring_summary");  // 쿠키에 저장된 농장 선택 해드리스트 
      let cookie = JSON.parse(localStorage.getItem( this.farm.code + "_selected_monitoring_summary"));  // 쿠키에 저장된 농장 선택 해드리스트 
      if (cookie && cookie.list.length > 0) {
        this.selectedHeaders = cookie.list;
        header_list = cookie.name_list;
        }
      else { // 없으면 초기화
        this.selectedHeaders = this.headers;
        this.selectedHeaders.forEach( item => header_list.push(item.value));
      }
      if (this.msgFlag) this.$store.commit("resMessage","");
      if (!this.farm || !this.farm.code ) {
        alert("축사/건물/구역 또는 농장/사업장를 선택하세요");
        this.$refs.building.focus();
        return;
      }
      this.loading = true;
      this.refreshWeatherInfo();

      let preItems = JSON.parse(sessionStorage.getItem("summaryData"));
      if (preItems) {
        this.items = preItems;
        sessionStorage.removeItem("summaryData");
        this.loading = false;
      } else {
        // console.log("리스트");
        Apis.monitoringSummary({
          farm_cd: this.farm && this.farm.code || "",
          building_cd: this.building && this.building.code || "",
          stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
        } ,(res) => {  // 정상처리
          this.items = res.data;
          if (this.msgFlag) this.$store.commit("resMessage",res.message);
          this.msgFlag = true;
          this.loading = false;
          let cookie_info = { company: this.company, farm: this.farm, building: this.building };
          let cookie_interval = this.setInterval;
          VueCookies.set("summary_info" ,cookie_info,"30d");
          VueCookies.set("summary_interval" ,cookie_interval,"30d");
          // console.log("path",this.$route.path);
          // setTimeout( () =>  this.getNowControllerValue(), 1000);
          this.$route.path == "/monitoring/summary" ? setTime = setTimeout( () =>  this.refreshList(), this.setInterval.min * 60 * 1000) : "" ;
          // console.log("setTime",setTime);
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("monitoringSummary 호출 오류",err);
          // alert("monitoringSummary 호출 오류" + err);
          this.$router.push("/");
          this.loading = false;
        })
      } 
    }, 
    getNowControllerValue() {
      this.items.forEach(((element,index) => {
        if (!element.controllers[0].mac) return;
        // console.log(element.controllers[0].mac,element.controllers[0].slave_id);
        setTimeout( async function() {
          // 슬레이브#2 현재온도 2022-05-27 혜린 추가요청
          if (element.controllers[1].mac) {
            await Apis.getNowControllerValue({
              mac: element.controllers[1].mac,
              slave_id: element.controllers[1].slave_id,
            } ,(res) => { 
              element.now_temp =  res.data.now_temp;
              });
          }
          // 슬레이브#1 현재온도
          if (element.controllers[0].mac) {
            await Apis.getNowControllerValue({
              mac: element.controllers[0].mac,
              slave_id: element.controllers[0].slave_id,
            } ,(res) => { 
              element.now_temp =  res.data.now_temp  + "∕" + element.now_temp;
              element.nowVent =  res.data.now_vent;
              });
          }},
          300 * index);
          }),
        )
    }, 
    
    async getFanTemp(item) {
      if (!item.controllers[0].mac) return;
        // 슬레이브#2 현재온도
      this.loading = true;

      await Apis.getNowControllerValue({
          mac: item.controllers[0].mac,
          slave_id: item.controllers[0].slave_id,
        },
        res => { 
          item.now_temp =  res.data.now_temp
          item.nowVent =  res.data.now_vent;
          this.loading = false;
        }
      );

      if (item.controllers[1]) {
        Apis.getNowControllerValue({
          mac: item.controllers[1].mac || "",
          slave_id: item.controllers[1].slave_id || 0,
          },
          res => { 
            item.now_temp  = item.now_temp + "∕" + res.data.now_temp;
          })
      }
    }, 
    refreshWeatherInfo() {
      Apis.monitoringWeather({
        farm_cd: this.farm.code,
      } ,(res) => {
        // NOTE: 해당 농장/사업장정보에 등록된 위치정보 기반 날씨 정보
        // console.log("weather=",res.data)
        this.weather_info = res.data;
      });
    },
    clickRow(item,row) {
      console.log("click Row",item, row)
      row && row.select(true);
    },
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      )
    },
    async changeCompany() {   // 하위 콤보 지우기;
      changed = true;  
      this.building = {}; this.buildingItems = [];
      this.farm = {}; this.farmItems = [];

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },        
    async comboFarms() {
      this.farmItems = [];
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      changed = true;  

      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.building = {};
      }
      this.buildingItems = [];
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      changed = true;  
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
      if (this.building && this.building.code) {
      this.refreshList();
      }
    },
    gotoBarometer(item) {
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));
      this.$router.push({
        name: "Barometer",
        params: { 
          company: this.company,
          farm: this.farm,
          building: {code: item.building_cd, name: item.building_name},
          room: {code: item.room_cd, name: item.room_name + " " + item.farm_name},
          }
      });
    },
    gotoEvent(item) {
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));
      this.$router.push({
        name: "TotalEvents",
        params: { 
                  farm: { company_cd: this.company.code, company_name: this.company.name,
                          code: item.farm_cd, name: item.farm_name,},
                  building: { company_cd: this.company.code, company_name: this.company.name,
                              farm_cd: item.farm_cd, farm_name: item.farm_name,
                              code: item.building_cd, name: item.building_name},
                  room: { company_cd: this.company.code, company_name: this.company.name,
                          farm_cd: item.farm_cd, farm_name: item.farm_name,
                          building_cd: item.building_cd, building_name: item.building_name,
                          code: item.room_cd, name: item.room_name + ' ' + item.farm_name},
                }
      });
    },
    gotoSettingControl(item) {
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));

      if (item.services.fancontrol) {
        this.$router.push({
          name: "ControlSet",
          params: { building: item.building_cd, room: item.room_cd }
        });
      } else {
      this.$router.push({
          name: "SettingControl",
          params: {
            company: this.company,
            farm: this.farm,
            building: {code: item.building_cd, name: item.building_name},
            room: {code: item.room_cd, name: item.room_name + ' ' + item.farm_name},
          }
        });
      }
    },

    gowatt(building){
      sessionStorage.setItem("summaryData",JSON.stringify(this.items));
      this.$router.push({
        name: "WattageDetail",
        params: { company: this.company, farm: this.farm, building:building }
      });
    }
    

  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.v-list .v-list-item--link:before{
  opacity: 0;
}
.v-list-item--disabled{
  display:none !important;
}
.v-input--dense.v-text-field--outlined .v-chip {
  height: 24px;
  margin-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
  font-size:13px;
  letter-spacing: -1px;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.link_btn{
  margin-right:  3px;
  width: 55px ;
  height: 25px;
  line-height: 0px;
  border-radius: 30px;
  background-color: #3a4f3f;
  line-height: 10px;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.32px;
  cursor: pointer;
 }
.link_btn_white{
  color: #3a4f3f;
  border: solid 0.5px;
  font-size: 12px;
  background-color: #f3f8f3;
 }
.link_btn_red{
  color: #3a4f3f;
  background-color:  #88191a;
 }
.selectedHeaders{
  max-width:290px;
}

.v-data-table::v-deep{
  .v-data-table__wrapper {

    *{
      border-color:transparent;
      border-spacing: 0 5px !important;
      letter-spacing:-1px;
      margin-top: -10px;
    }     

    table { 
      th {
      // text-align: center !important;
      font-size:15px !important;
      height:35px;
      background-color: #e8e8e8 !important;
      border: none !important;
      box-shadow: none !important;
      }

      td {
        font-size:17px;
        border: solid 1px transparent;
        border-style: solid none;
        padding: 8px;
        background-color: #f9f9f9;
        height: 45px !important;
      }

      td:not(:first-child) {
        text-align: center;
      }
      

      td:first-child {
        // width:180px;
        letter-spacing: -1.75px !important;
        border-left-style: solid;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        text-align: center;
        border-right-style: solid;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
  thead.v-data-table-header tr {
    th {
      white-space: nowrap !important;
      background-color: white !important;
      color: #3a4f3f !important;
      box-shadow: none !important;
      border: none !important;
      border-bottom: none !important;
      text-align:left;
    } 
    th:not(:first-child) {
      border:1px solid #e8e8e8 !important;
      border-radius: 50px;
      text-align:center !important;
      padding-right:5px;
    } 
  }
}
.fan_info{
  letter-spacing: 0px !important;
}
.text_red{
  color:#88191a;
  font-size: 14px !important;
}
.cell_red{
  background-color:#88191a !important;
  font-weight:500;
  padding:8px;
  border-radius:10px;
  color:white;
  animation: blink-effect 1s step-end infinite;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
.text_blue{
  color:#004297;
  font-size: 14px !important;
}
.text_red{
  color:#88191A;
}
.text_gray{
  color:#5c5c5c;
  font-size: 15px !important;
}
.unit{
  color:#5c5c5c;
  font-size: 12px !important;
}
.unit_ppm{
  color:#5c5c5c;
  font-size: 10px !important;
}
.no_unit{
  font-size: 0px !important;
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}

.icon_bolt{
  width:15px;
  filter: invert(77%) sepia(86%) saturate(346%) hue-rotate(340deg) brightness(100%) contrast(94%);
}
.watt_info{
  display:inline-block;
  background:white;
  width:100px;
  padding:1px;
  margin-left:5px;
  border-radius: 10px;
  cursor:pointer;
}
.watt_number{
  display:inline-block;
  width:60px;
  letter-spacing: -1px;
  text-align:center;
}
.watt_info:hover{
  background: #f8ffcf;
  transition: all .5s ease;
}
.header_layout{
  margin-top: 5px; 
  display:flex; 
  justify-content: flex-end;
}
@media (min-width: 360px) and (max-width: 767px) {
  .header_layout{
    flex-direction: column; 
  }
  .selectedHeaders{
    margin:5px !important;
    max-width:400px;
    // justify-content: flex-end !important;
    height:40px;
  }
  .v-data-table::v-deep{
    table{
      td:first-child{
        font-size:0.8rem !important;
        width:150px !important;
      }
    }
  }
}


</style>